// Import theme caret SCSS
@import "mixins/caret";

// Import theme image background SCSS
@import "mixins/image-bg";

// Import theme video background SCSS
@import "mixins/video-bg";

// Import theme animation SCSS
@import "mixins/animation";

// Import theme star rate SCSS
@import "mixins/star-rate";@mixin button-outline-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  --#{$prefix}btn-color: var(--#{$prefix}btn-outline-#{$color}-color, #{$color});
  --#{$prefix}btn-border-color: var(--#{$prefix}btn-outline-#{$color}-border-color, #{$color});
  --#{$prefix}btn-hover-color: var(--#{$prefix}btn-outline-#{$color}-hover-color, #{$color-hover});
  --#{$prefix}btn-hover-bg: var(--#{$prefix}btn-outline-#{$color}-hover-bg, #{$active-background});
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}btn-outline-#{$color}-hover-border-color, #{$active-border});
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
  --#{$prefix}btn-active-color: var(--#{$prefix}btn-hover-color);
  --#{$prefix}btn-active-bg: var(--#{$prefix}btn-hover-bg);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}btn-hover-border-color);
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$color};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: #{$color};
  --#{$prefix}gradient: none;
}
