@mixin background-cover($bg-size: '', $bg-attachment: '') {
    background-repeat: no-repeat;
    background-position: center center;

    @if $bg-size != '' {
        background-size: $bg-size;
    }

    @if $bg-attachment != '' {
        background-attachment: $bg-attachment !important;
    }
}


@mixin background-overlay($bg-opacity) {
    &.bg-overlay-#{$bg-opacity*1000}:before {
        background: rgba($black, $bg-opacity);
    }

    &.bg-overlay-#{$bg-opacity*1000}-light:before {
        background: rgba($white, $bg-opacity);
    }
}
