.dropdown-menu {
    --#{$prefix}dropdown-box-shadow: #{$dropdown-box-shadow};
    box-shadow: var(--#{$prefix}dropdown-box-shadow);
}

[data-bs-theme="dark"] .dropdown-menu,
.dropdown-menu-dark {
    box-shadow: none;
}

.dropdown-toggle {
    @include caret-custom();
}

.dropup {
    .dropdown-toggle {
        @include caret-custom(up);
    }
}

.dropend {
    .dropdown-toggle {
        @include caret-custom(end);
    }
}

.dropstart {
    .dropdown-toggle {
        @include caret-custom(start);
    }
}
