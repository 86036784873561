// Navbar
.navbar {
    --#{$prefix}navbar-hover-color: #{$navbar-light-hover-color};
    --#{$prefix}navbar-active-color: #{$navbar-light-active-color};

    --#{$prefix}navbar-brand-mobile-max-height: #{$navbar-brand-mobile-max-height};

    --#{$prefix}navbar-dropdown-toggle-icon-width: #{$navbar-dropdown-toggle-icon-width};
    --#{$prefix}navbar-dropdown-toggle-icon-height: #{$navbar-dropdown-toggle-icon-height};
    --#{$prefix}navbar-dropdown-toggle-icon-margin-end: #{$navbar-dropdown-toggle-icon-margin-end};   
    --#{$prefix}navbar-dropdown-toggle-icon-font-size: #{$navbar-dropdown-toggle-icon-font-size};

    --#{$prefix}navbar-dropdown-link-hover-bg: #{$navbar-dropdown-link-hover-bg};
    --#{$prefix}navbar-dropdown-active-bg: #{$navbar-dropdown-active-bg};
    --#{$prefix}navbar-dropdown-link-hover-color: #{$navbar-dropdown-link-hover-color};
    --#{$prefix}navbar-dropdown-link-active-color: #{$navbar-dropdown-link-active-color};

    --#{$prefix}navbar-mega-menu-min-width: #{$navbar-mega-menu-min-width};
    --#{$prefix}navbar-mega-menu-item-padding-x: #{$navbar-mega-menu-item-padding-x};
    --#{$prefix}navbar-mega-menu-item-padding-y: #{$navbar-mega-menu-item-padding-y};
    --#{$prefix}navbar-mega-menu-link-hover-bg: #{$navbar-mega-menu-link-hover-bg};
    --#{$prefix}navbar-mega-menu-link-active-bg: #{$navbar-mega-menu-link-active-bg};
    --#{$prefix}navbar-mega-menu-link-color: #{$navbar-mega-menu-link-color}; 
    --#{$prefix}navbar-mega-menu-link-hover-color: #{$navbar-mega-menu-link-hover-color};
    --#{$prefix}navbar-mega-menu-link-active-color: #{$navbar-mega-menu-link-active-color};
    --#{$prefix}navbar-mega-menu-link-disabled-color: #{$navbar-mega-menu-link-disabled-color};

    // Dropdown toggle icon
    & .dropdown .dropdown-toggle-hover {
        position: relative;

        & .dropdown-toggle-icon {
            font-size: var(--#{$prefix}navbar-dropdown-toggle-icon-font-size);
            right: var(--#{$prefix}navbar-dropdown-toggle-icon-margin-end);
            width: var(--#{$prefix}navbar-dropdown-toggle-icon-width);
            height: var(--#{$prefix}navbar-dropdown-toggle-icon-height);
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
        }
    }
    // Dropdown item
    & .dropdown-menu .dropdown-item {
        &:hover,
        &:focus {
            color: var(--#{$prefix}navbar-dropdown-link-hover-color);
            background: var(--#{$prefix}navbar-dropdown-link-hover-bg);
        }

        &.active,
        &:active {
            color: var(--#{$prefix}navbar-dropdown-link-active-color);
            background: var(--#{$prefix}navbar-dropdown-active-bg);
        }
    }
    // Mega menu
    & .dropdown-menu.mega-menu {
        & .mega-menu-title,
        & .mega-menu-item {
            min-width: var(--#{$prefix}navbar-mega-menu-min-width);
            display: block;
            z-index: var(--#{$prefix}dropdown-zindex);
            width: 100%;
            padding: var(--#{$prefix}navbar-mega-menu-item-padding-y) var(--#{$prefix}navbar-mega-menu-item-padding-x);
            clear: both;
            font-weight: $font-weight-normal;
            color: var(--#{$prefix}navbar-mega-menu-link-color);
            text-align: inherit;
            text-decoration: if($link-decoration == none, null, none);
            white-space: nowrap;
            background-color: transparent;
            border: 0;
        }

        & .mega-menu-item {
            &:hover,
            &:focus {
                color: var(--#{$prefix}navbar-mega-menu-link-hover-color);
                text-decoration: if($link-hover-decoration == underline, none, null);
                background-color: var(--#{$prefix}navbar-mega-menu-link-hover-bg);
            }

            &.active,
            &:active {
                color: var(--#{$prefix}navbar-mega-menu-link-active-color);
                text-decoration: if($link-hover-decoration == underline, none, null);
                background-color: var(--#{$prefix}navbar-mega-menu-link-active-bg);
            }

            &.disabled,
            &:disabled {
                color: var(--#{$prefix}navbar-mega-menu-link-disabled-color);
                pointer-events: none;
                background-color: transparent;
            }
        }
    }

    // Navbar brand
    @include media-breakpoint-down(sm) {
        & .navbar-brand .logo {
            max-height: var(--#{$prefix}navbar-brand-mobile-max-height);
        }
    }
}

// Navbar Nav
.navbar-nav {
    --#{$prefix}navbar-nav-hover-color: #{$navbar-light-hover-color};
    --#{$prefix}navbar-nav-active-color: #{$navbar-light-active-color};

    & .show > .nav-link {
        color: var(--#{$prefix}navbar-nav-hover-color);
    }

    & .nav-link.active {
        color: var(--#{$prefix}navbar-nav-active-color);
    }
}

// Navbar expand
.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($next) {
                .navbar-nav .dropdown {

                    & .dropdown-menu, & .dropdown-menu.mega-menu {
                        box-shadow: none !important;
                        border-color: transparent;
                    }

                }
            }
            @include media-breakpoint-up($next) {
                .navbar-nav .dropdown {

                    & .dropdown-menu.mega-menu {
                        min-width: max-content;
                    }

                    & .dropdown-toggle-hover {
                        position: initial;

                        & .dropdown-toggle-icon {
                            width: unset;
                            height: unset;
                            position: unset;
                            transform: unset;
                            display: unset;
                        }
                    }
                }
            }
        }
    }
}