// Background video
.video-wrapper {
    position: relative;
    overflow: hidden;

    &.bg-overlay:after {
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;
        background-color: rgba($black, 0.35);
    }

    @for $i from 3 through 9 {
        $bg-opacity: calc($i / 10);
        @include background-overlay($bg-opacity);
    }

    &.ratio::before {
        content: unset;
    }

    &.bg-overlay .bg-content {
        position: relative;
        z-index: 2;
        height: 100%;
    }
}
