#header {
    transition: 0.35s all ease-in-out;
    -webkit-transition: 0.35s all ease-in-out;
    -moz-transition: 0.35s all ease-in-out;
    -ms-transition: 0.35s all ease-in-out;
    -o-transition: 0.35s all ease-in-out;

    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    &[data-bs-theme=dark] {
        --#{$prefix}bg-opacity: 0;

        & .navbar::before {
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            position: absolute;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%);
            z-index: -1;
            opacity: 1;
            transition: 0.35s all ease-in-out;
            -webkit-transition: 0.35s all ease-in-out;
            -moz-transition: 0.35s all ease-in-out;
            -ms-transition: 0.35s all ease-in-out;
            -o-transition: 0.35s all ease-in-out;
        }
    }

    &.header-sticked {
        --#{$prefix}bg-opacity: 100;
        border-bottom-color: var(--#{$prefix}border-color);

        & .navbar::before {
            opacity: 0;
        }
    }
}