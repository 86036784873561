@import 'variables';
.bg-custom {
    background-color: $ovr-red;
    a:hover {
        color: $white !important;
        text-decoration: underline;
    }
}

.room-opt {
    * > strong {
        font-weight: 400;
    }
}

.img-container {
    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 25%; 
    }
}

.gallery-banner {
    background-position: center 25% !important;
}

.height-full {
    @media (min-width: 1080px) {
    height: 100%;
    &-rem {
        height: calc(100% - 1rem);
    }
    }
}

#reservation {
    & > .container {
        #checkRooms {

        }
    }
    
}

#bookingEngineFrame {
    width: 100%;
    // margin-top: 24px;
    height: calc(100vh - 200px);
    border: none;

}

.floating-chat {
    position: fixed;
    right: 25px;
    bottom: 75px;
    z-index: 9999;
  
    .toggle-button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: none;
      background: $ovr-red;
      color: #fff;
      cursor: pointer;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      outline: none;
    }
  
    .chat-window {
      position: absolute;
      bottom: 0px;
      right: 55px;
      width: 300px;
      border: 1px solid $ovr-red;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      display: none; // hidden initially
      z-index: 999;
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;
      opacity: 0;
      transform: translateY(20px);

      @media (max-width: 500px) {
        right: 0;
        bottom: 105px;
        width: min(calc(100vw - 50px), 300px);
      }
  
      &.open {
        opacity: 1;
        transform: translateY(0);
      }
  
      .chat-header {
        background: $ovr-red;
        color: #fff;
        padding: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        text-align: center;
      }
  
      .chat-body {
        display: flex;
        flex-direction: column;
  
        .chat-messages {
          height: 400px;
          overflow-y: auto;
          padding: 10px;
          background: #f9f9f9;
  
          // User message styling: entire box aligned to right
          .chat-message {
            background: #e2e2e2;
            color: #333;
            padding: 8px 12px;
            margin-bottom: 8px;
            border-radius: 8px;
            max-width: 80%;
            margin-left: auto; // pushes the whole message box to the right
            text-align: left;
          }
  
          // Bot reply text styling (for use within the bot container)
          .chat-reply {
            background: $ovr-red;
            color: #fff;
            padding: 8px 12px;
            margin-bottom: 8px;
            border-radius: 8px;
            max-width: 80%;
          }
  
          // Container for bot message with avatar and text
          .bot-message-container {
            display: flex;
            align-items: flex-start;
            margin-bottom: 8px;
            gap: 5px;
          }
  
          .bot-avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: $ovr-red;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 1rem;
            flex-shrink: 0;
          }
  
          // Style for thinking animation
          .chat-thinking {
            background: $ovr-red;
            color: #fff;
            min-height: 1em;
            padding: 8px 12px;
            margin-bottom: 8px;
            border-radius: 8px;
            max-width: 80%;
            align-self: flex-start;
            display: flex;
            align-items: center;
  
            .dot {
              width: 6px;
              height: 6px;
              background-color: #fff;
              border-radius: 50%;
              margin-right: 4px;
              opacity: 0.2;
              animation: blink 1.4s infinite both;
            }
            .dot:nth-child(2) {
              animation-delay: 0.2s;
            }
            .dot:nth-child(3) {
              animation-delay: 0.4s;
            }
          }
        }
  
        .chat-input {
          display: flex;
          padding: 10px;
          border-top: 1px solid #ccc;
  
          input {
            flex: 1;
            padding: 5px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 100%;
          }
  
          button {
            margin-left: 5px;
            padding: 5px 10px;
            background: $ovr-red;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
  
  /* Fade in animation for new messages */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .message-animate {
    animation: fadeIn 0.3s ease-out;
  }
  
  @keyframes blink {
    0% { opacity: 0.2; }
    20% { opacity: 1; }
    100% { opacity: 0.2; }
  }
  
  .ws-btn {
    position: fixed;
    bottom: 125px;
    right: 25px;
    cursor: pointer;
    z-index: 9999;
  }