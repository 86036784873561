:root,
[data-bs-theme="light"] {
  --#{$prefix}btn-outline-primary-color: #{$primary};
  --#{$prefix}btn-outline-primary-border-color: #{$primary};
  --#{$prefix}btn-outline-primary-hover-color: #{$white};
  --#{$prefix}btn-outline-primary-hover-bg: #{$primary};
  --#{$prefix}btn-outline-primary-hover-border-color: #{$primary};
}

@if $enable-dark-mode {
  [data-bs-theme="dark"] {
    --#{$prefix}btn-outline-primary-color: white;
    --#{$prefix}btn-outline-primary-border-color: white;
    --#{$prefix}btn-outline-primary-hover-color:#{$primary};
    --#{$prefix}btn-outline-primary-hover-bg: white;
    --#{$prefix}btn-outline-primary-hover-border-color:white;
  }
}
