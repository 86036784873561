/* Control with icon */
.control-icon {
    position: relative;

    > .form-control,
    > .form-select,
    button.form-select {
        padding-left: 3rem;

        &.form-control-lg,
        &.form-select-lg {
            padding-left: 2.75rem;
        }
    }

    > .hicon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;
        // z-index: 3!important;

        .control-icon-lg & {
            left: 0.7rem;
            font-size: 130%;
        }

        .control-icon-sm & {
            left: 1rem;
            font-size: 120%;
        }
    }
}
