//Custom caret

@mixin caret-down-custom {
    border: 0;
    content: $icon-caret-down;
}

@mixin caret-up-custom {
    border: 0;
    content: $icon-caret-up;
}

@mixin caret-end-custom {
    border: 0;
    content: $icon-caret-end;
}

@mixin caret-start-custom {
    content: $icon-caret-start;
}

@mixin caret-custom($direction: down) {

    @if $enable-caret {

        &::after, &::before {
            font-family: $font-family-icons;
            display: inline-block;
            line-height: 1;
            font-weight: 400;
            font-style: normal;
            font-size: $icon-caret-size;
            speak: none;
            text-decoration: inherit;
            text-transform: none;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: inline-block;
            vertical-align: $caret-vertical-align;
            content: "";
            border: 0;
        }

        &::after {
            margin-left: $caret-spacing;
        }

        &::before {
            margin-right: $caret-spacing;
        }

        @if $direction == down {
            &::after {
                @include caret-down-custom();
            }
        }
        @else if $direction == up {
            &::after {
                @include caret-up-custom();
            }
        }
        @else if $direction == end {
            &::after {
                @include caret-end-custom();
            }
        }
        @else if $direction == start {
            &::before {
                @include caret-start-custom();
            }
        }

        &:empty::after {
            margin-left: 0;
        }
    }
}
