@mixin animation($name, $duration: 0.35s, $delay: 0s) {
    $prefixes: -webkit-, -moz-, -ms-, -o-, '';

    @each $prefix in $prefixes {
        #{$prefix}animation-name: $name;
        #{$prefix}animation-duration: $duration;
        #{$prefix}animation-delay: $delay;
        #{$prefix}animation-fill-mode: both;
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-ms-keyframes #{$name} {
        @content;
    }

    @-o-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}
