// Backgound image
.image-wrapper {
   
    @include background-cover($bg-size: 'cover', $bg-attachment: '');
    position: relative;
    z-index: 0;

    &:not(.mobile) {
        @include background-cover($bg-size: '', $bg-attachment: 'fixed');
    }

    &.bg-auto {
        @include background-cover($bg-size: 'auto', $bg-attachment: 'scroll');
    }

    &.bg-full {
        @include background-cover($bg-size: '100%', $bg-attachment: 'scroll');
    }

    &.bg-cover {
        @include background-cover($bg-size: 'cover', $bg-attachment: 'scroll');
    }

    &.bg-overlay {
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.5);
        }
        &.video-section:before,
        &.rooms-banner:before,
        &.gallery-banner:before{
            background: radial-gradient(rgba($black, .7) 40%, rgba($black, .4));
        }
        &.room-banner::before {
            background: radial-gradient(rgba($black, .6) 20%, rgba($black, .4));
        }
        &.booking-section:before {	
            background: radial-gradient(circle farthest-corner at 60px 60px, rgba($black, .8) 20%, rgba($black, .4));
        }
    }

    @for $i from 3 through 9 {
        $bg-opacity: calc($i / 10);
        @include background-overlay($bg-opacity);
    }

    &.bg-overlay .bg-content {
        position: relative;
        z-index: 2;
        height: 100%;
    }

    &.card:before {
        border-radius: $border-radius !important;
    }

    @media (max-width: 991.98px) {
        &.bg-full {
            @include background-cover($bg-size: 'cover', $bg-attachment: '');
        }
    }
}
