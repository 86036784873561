// Strong/Bold
b,
strong {
    font-weight: $font-weight-semibold;
}

// Link
a:hover {
    transition: #{$transition-base};
}

// Heading
h1, h2, h3, h4, h5, h6 {
    font-family: ($h-font-family);
}

// Mark
// Mark

mark {
    font-family: $font-family-code;
    font-size: $code-font-size;
    font-weight: var(--#{$prefix}body-font-weight);
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.065);
    border-radius: var(--#{$prefix}border-radius);
}

// Text Selection Color
@each $ex in '::', '::-moz-', '::-webkit-' {
    #{$ex}selection {
        background: var(#{$text-selection-color});
        color: var(#{$text-selection-bg});
    }
}

// Scroll bar
@each $ex in '::', '::-moz-', '::-webkit-' {

    *#{$ex}scrollbar {
        width: #{$scrollbar-width};
    }

    *#{$ex}scrollbar-track {
        background-color: var(#{$scrollbar-track-bg});
    }

    *#{$ex}scrollbar-thumb {
        border: #{$scrollbar-thumb-border} solid transparent;
        background-clip: content-box;
        background-color: var(#{$scrollbar-thumb-bg});
    }
}
