/* START CONFIGURATION */

// Import bootstrap functions SCSS
@import "../../../node_modules/bootstrap/scss/functions";

// Import your custom variables SCSS
@import 'custom-variables';
// Import your custom variables SCSS (dark mode)
@import "custom-variables-dark";

// Import theme color variables SCSS 
@import "color_variables";

// Import theme variables SCSS 
@import 'variables';
// Import bootstrap variables SCSS
@import "../../../node_modules/bootstrap/scss/variables";

// Import theme variables dark SCSS 
@import "variables-dark";
// Import bootstrap variables SCSS (dark mode) 
@import "../../../node_modules/bootstrap/scss/variables-dark";

// Import bootstrap maps SCSS 
@import "../../../node_modules/bootstrap/scss/maps";

// Import bootstrap mixins SCSS 
@import "../../../node_modules/bootstrap/scss/mixins";
// Import theme mixin SCSS
@import "mixins";

// Import bootstrap utilities SCSS 
@import "../../../node_modules/bootstrap/scss/utilities";
// Import theme utilities SCSS 
@import "utilities";

/* END CONFIGURATION */

/*-----------------------------------------------------------*/

/* START LAYOUT & COMPONENTS */

//Import Google fonts
@import url($path-to-fonts);

// Import bootstrap root SCSS
@import "../../../node_modules/bootstrap/scss/root";
// Import theme root SCSS
@import "root";

// Import bootstrap reboot SCSS 
@import "../../../node_modules/bootstrap/scss/reboot";
// Import theme reboot SCSS 
@import "reboot";

// Import bootstrap type SCSS 
@import "../../../node_modules/bootstrap/scss/type";

// Import bootstrap images SCSS 
@import "../../../node_modules/bootstrap/scss/images";
// Import theme images SCSS
@import "image";

// Import bootstrap containers SCSS 
@import "../../../node_modules/bootstrap/scss/containers";

// Import bootstrap grid SCSS 
@import "../../../node_modules/bootstrap/scss/grid";

// Import bootstrap tables SCSS 
@import "../../../node_modules/bootstrap/scss/tables";
// Import theme tables SCSS 
@import "tables";

// Import bootstrap forms SCSS 
@import "../../../node_modules/bootstrap/scss/forms";
// Import theme form SCSS
@import "forms";

// Import bootstrap buttons SCSS 
@import "../../../node_modules/bootstrap/scss/buttons";
// Import theme button SCSS
@import "buttons";

// Import bootstrap transitions SCSS 
@import "../../../node_modules/bootstrap/scss/transitions";
// Import theme transitions SCSS
@import "transitions";

// Import bootstrap dropdown SCSS 
@import "../../../node_modules/bootstrap/scss/dropdown";
// Import theme dropdown SCSS
@import "dropdown";

// Import bootstrap button-group SCSS 
@import "../../../node_modules/bootstrap/scss/button-group";

// Import bootstrap nav SCSS 
@import "../../../node_modules/bootstrap/scss/nav";
// Import theme Nav SCSS
@import "nav";

// Import bootstrap navbar SCSS 
@import "../../../node_modules/bootstrap/scss/navbar";
// Import theme Navbar SCSS
@import "navbar";

// Import bootstrap card SCSS 
@import "../../../node_modules/bootstrap/scss/card";
// Import theme card SCSS
@import "card";

// Import bootstrap accordion SCSS 
@import "../../../node_modules/bootstrap/scss/accordion";

// Import bootstrap breadcrumb SCSS 
@import "../../../node_modules/bootstrap/scss/breadcrumb";
// Import theme breadcrumb SCSS 
@import "breadcrumb";

// Import bootstrap pagination SCSS 
@import "../../../node_modules/bootstrap/scss/pagination";

// Import bootstrap badge SCSS 
@import "../../../node_modules/bootstrap/scss/badge";
// Import theme alert SCSS 
@import "alert";

// Import bootstrap alert SCSS 
@import "../../../node_modules/bootstrap/scss/alert";

// Import bootstrap button-group SCSS 
@import "../../../node_modules/bootstrap/scss/progress";

// Import bootstrap button-group SCSS 
@import "../../../node_modules/bootstrap/scss/list-group";

// Import bootstrap close SCSS 
@import "../../../node_modules/bootstrap/scss/close";

// Import bootstrap button-group SCSS 
//@import "../../../node_modules/bootstrap/scss/toasts";

// Import bootstrap modal SCSS 
@import "../../../node_modules/bootstrap/scss/modal";

// Import bootstrap tooltip SCSS 
// @import "../../../node_modules/bootstrap/scss/tooltip";

// Import bootstrap popover SCSS 
// @import "../../../node_modules/bootstrap/scss/popover";

// Import bootstrap carousel SCSS 
@import "../../../node_modules/bootstrap/scss/carousel";

// Import bootstrap spinners SCSS 
@import "../../../node_modules/bootstrap/scss/spinners";

// Import bootstrap offcanvas SCSS 
@import "../../../node_modules/bootstrap/scss/offcanvas";

// Import bootstrap placeholders SCSS 
// @import "../../../node_modules/bootstrap/scss/placeholders";

/* END LAYOUT & COMPONENTS */

/*-----------------------------------------------------------*/

/* START HELPERS */

// Import bootstrap helpers SCSS
@import "../../../node_modules/bootstrap/scss/helpers";
// Import theme helpers SCSS
@import "helpers";

/* END HELPERS */

/*-----------------------------------------------------------*/

/* START UTILITIES */

// Import bootstrap utilities SCSS
@import "../../../node_modules/bootstrap/scss/utilities/api";

/* END UTILITIES */

/*-----------------------------------------------------------*/

/* START EXTEND */
@import "hero";
@import "header";
@import 'extra';

/* END EXTEND */

/*-----------------------------------------------------------*/

/* START YOUR CUSTOM */

@import 'custom';

/* START YOUR CUSTOM */
